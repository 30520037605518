const timezones = [
  {
      text: 'EST',
      value: 'est',
      key: 'EST',
  },
  {
      text: 'CST',
      value: 'cst',
      key: 'CST',
  },
  {
      text: 'MST',
      value: 'mst',
      key: 'MST',
  },
  {
      text: 'PST',
      value: 'pst',
      key: 'PST',
  },
];

export default timezones;
