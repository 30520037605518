import React from "react"

import colors from "../colors"

import { PageLayout } from "../components/layouts"
import SEO from "../components/seo"

import PageWrapper from "../components/pageWrapper"
import DealerApplyForm from "../components/apply/DealerApplyForm"

const iconProps = {
  size: "60px",
  color: colors.darkPurple,
}

const BecomeADealer = () => (
  <PageLayout>
    <SEO title="Long Term Care Webinar | Corey Rieck" 
         description="Register for our Free Webinar to learn how long term care works, why you need to secure a policy early, and the care venues available to you."  />
    <PageWrapper>
      <h1>Webinar Registration</h1>
      <p>
        Would you like to learn more about long term care and how it can impact your family and finances? Register for our webinar:{" "}
        <a
          href="https://thelongtermcareplanninggroup.easywebinar.live/registration-7"
          target="_blank"
        >
          What You Need to Know About Long Term Care, Related Expenses and Planning
        </a>
        .
      </p>
      <div className="widget_wrapper">
        <div
          className="ewp_form_container ewp_widget_container"
          webinar_id="QdvUctflYjEnWYhjc65cdg=="
        ></div>
      </div>
      <p>By selecting "Opt-In To SMS" above, you have opted into receiving text messages regarding your Long Term Care Insurance needs with The Long Term Care Planning Group. Message and data rates may apply. Message frequency varies depending on the activity of your account. You can opt out by responding STOP at any time. The Long Term Care Planning Group does not share SMS opt-in consent with any third-party organization. View our <a href="/privacy-policy/" target="_blank">Privacy Policy</a> for more details.</p>
      <p>Sometimes the best way to learn more about a complex topic is to attend a short webinar to start with the basics. Even if you already have done long term care planning, you may still have unanswered questions about your coverage or plan.</p>
      <p>This webinar is about 20 minutes and after the webinar you'll receive a summary PDF overview for your review.</p>
      <p>The Long Term Care Planning Group knows how to help protect your financial assets and wealth management with a funding strategy for long term care. Corey and his team can answer your questions and explain why you should not rely on government programs or only your family members for long term care.</p>
      <p>When you decide to explore long term care financial solutions, we take you step-by-step through a highly confidential and systematic process to optimize your funding options.</p>
      <p>Here's how we get you moving forward with your long term care insurance needs:</p>
       <ul>
         <li>We review existing personal long term care policies.</li>
         <li>Discuss personal options for long term care funding.</li>
         <li>Assist financial advisors and insurance agents with a review of their clients’ existing long term care policies or discuss their funding options.</li>
       </ul>
         
    </PageWrapper>
  </PageLayout>
)

export default BecomeADealer
