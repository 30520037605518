import React, { Component } from "react"
import axios from "axios"
import * as Yup from "yup"
import { Button, Icon, Modal } from "semantic-ui-react"

import { phone } from "../../utils/validators"
import usStates from "../../utils/data/usStates"
import timezones from "../../utils/data/timezones"
import BasicForm from "../basicForm"

import trackEvent from "../../utils/trackEvent"

const fields = [
  {
    name: "name",
    label: "Business Name",
    initialValue: "",
    required: true,
    schema: () => Yup.string().required("Required"),
  },
  {
    grouped: true,
    fields: [
      {
        name: "email",
        label: "Email",
        initialValue: "",
        required: true,
        schema: () =>
          Yup.string()
            .email("Not a valid email")
            .required("Required"),
      },
      {
        name: "phone",
        label: "Phone",
        initialValue: "",
        required: true,
        schema: () =>
          Yup.string()
            .matches(phone, "Phone number is not valid")
            .required("Required"),
      },
    ],
  },
  {
    name: "address_1",
    label: "Address",
    initialValue: "",
    required: true,
    schema: () => Yup.string().required("Required"),
  },
  {
    name: "address_2",
    label: "Address 2",
    initialValue: "",
    required: false,
    schema: () => null,
  },
  {
    grouped: true,
    fields: [
      {
        name: "city",
        label: "City",
        initialValue: "",
        required: true,
        schema: () => Yup.string().required("Required"),
      },
      {
        name: "state",
        label: "State",
        initialValue: null,
        search: true,
        options: usStates,
        select: true,
        required: true,
        schema: () =>
          Yup.string()
            .nullable()
            .required("Required"),
      },
      {
        name: "zip",
        label: "Zip",
        initialValue: "",
        required: true,
        schema: () =>
          Yup.string()
            .min(5, "Too Short!")
            .max(5, "Too Long!")
            .required("Required"),
      },
    ],
  },
  {
    name: "time_zone",
    label: "Time Zone",
    initialValue: null,
    options: timezones,
    search: true,
    select: true,
    required: true,
    schema: () =>
      Yup.string()
        .nullable()
        .required("Required"),
  },
  {
    name: "referred_by",
    label: "Referred By",
    initialValue: null,
    search: true,
    options: [
      { value: "Carrier Enterprise", text: "Carrier Enterprise" },
      { value: "Comfort Supply", text: "Comfort Supply" },
      { value: "Dale Supply", text: "Dale Supply" },
      { value: "Gainer Partners", text: "Gainer Partners" },
      { value: "Goodman", text: "Goodman" },
      { value: "Google", text: "Google" },
      { value: "HVAC Grow", text: "HVAC Grow" },
      { value: "JB Warranties", text: "JB Warranties" },
      { value: `Mingledorff's`, text: `Mingledorff's` },
      { value: "Other", text: "Other" },
      { value: "Praxis S-10", text: "Praxis S-10" },
      { value: "Shearer Supply", text: "Shearer Supply" },
      { value: "Trane DSO", text: "Trane DSO" },
      { value: "Woodsman and Bozeman", text: "Woodsman and Bozeman" },
      { value: "Yandle Witherspoon", text: "Yandle Witherspoon" },
    ],
    select: true,
    required: true,
    schema: () =>
      Yup.string()
        .nullable()
        .required("Required"),
  },
]

class DealerApplyForm extends Component {
  state = {
    error: null,
    loading: false,
    success: false,
  }

  componentDidMount = () => {
    trackEvent({
      category: "apply",
      label: "dealer",
      action: "button",
      value: 1,
    })
  }

  onSubmit = formData => {
    this.setState({ loading: true, error: null })

    axios
      .post(`/api/dealer-create/`, {
        ...formData,
        status: 0,
        country: "US",
      })
      .then(() => {
        this.setState({ loading: false, success: true })
      })
      .then(() => {
        trackEvent({
          category: "apply",
          label: "dealer",
          action: "submit",
          value: 1,
        })
      })
      .catch(err => {
        this.setState({ loading: false, error: err.response.data.ui_message })
      })
  }

  render() {
    const { success } = this.state

    return (
      <React.Fragment>
        {!success && (
          <BasicForm
            buttonText="Apply Now"
            error={this.state.error}
            fields={fields}
            loading={this.state.loading}
            onSubmit={this.onSubmit}
            formSize="tiny"
          />
        )}
        {success && (
          <div style={{ textAlign: "center" }}>
            <Icon
              name="check circle"
              color="green"
              size="huge"
              style={{ marginBottom: "10px" }}
            />
            <h1 style={{ marginBottom: "10px" }}>Success!</h1>
            <p>We've received your request and will contact you soon.</p>
          </div>
        )}
      </React.Fragment>
    )
  }
}

export default DealerApplyForm
