import React from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export default function trackEvent({ category, label, action, value = null }) {
  if (false) return

  trackCustomEvent({
    category: category.toLowerCase().replace(" ", "-"),
    label: label.toLowerCase().replace(" ", "-"),
    action: action.toLowerCase().replace(" ", "-"),
    value,
  })
}
